import Pagination1 from '@/assets/img/tmp/mypage/dashboard_obj1@2x.png';
import Pagination1H from '@/assets/img/tmp/mypage/dashboard_obj1_h@2x.png';
import Pagination2 from '@/assets/img/tmp/mypage/dashboard_obj2@2x.png';
import Pagination2H from '@/assets/img/tmp/mypage/dashboard_obj2_h@2x.png';
import Pagination3 from '@/assets/img/tmp/mypage/dashboard_obj3@2x.png';
import Pagination3H from '@/assets/img/tmp/mypage/dashboard_obj3_h@2x.png';
import Pagination4 from '@/assets/img/tmp/mypage/dashboard_obj4@2x.png';
import Pagination4H from '@/assets/img/tmp/mypage/dashboard_obj4_h@2x.png';
import Pagination5 from '@/assets/img/tmp/mypage/dashboard_obj5@2x.png';
import Pagination5H from '@/assets/img/tmp/mypage/dashboard_obj5_h@2x.png';

// 후원문구 데이터
// price는 단가를 뜻함 (나눗셈으로 연산한 이유는, 단가가 딱 떨어지지 않는 경우도 정확하게 계산하기 위함)
export const SUPPORT_PHRASE_DATA = {
  nutrition: {
    name: `영양`,
    icon: Pagination1,
    iconActive: Pagination1H,
    items: [
      {
        name: `영양실조치료식`,
        price: 50000 / 100,
        unit: `봉`,
      },
      {
        name: `고영양비스킷`,
        price: 35000 / 100,
        unit: `봉`,
      },
      {
        name: `영양실조치료우유`,
        price: 50000 / 8,
        unit: `캔`,
      },
      {
        name: `철분보충제`,
        price: 30000 / 2000,
        unit: `정`,
      },
      {
        name: `복합미량영양소`,
        price: 30000 / 660,
        unit: `포`,
      },
      /* 2024년 비활성
      {
        name: `영양상태 측정 테이프`,
        price: 3000 / 100,
        unit: `개`,
      },
      */
    ],
  },
  health: {
    name: `보건`,
    icon: Pagination2,
    iconActive: Pagination2H,
    items: [
      {
        name: `응급처치키트`,
        price: 55000 / 1,
        unit: `개`,
      },
      {
        name: `마스크`,
        price: 10000 / 100,
        unit: `개`,
      },
      {
        name: `구강수분보충염 (ORS)`,
        price: 30000 / 200,
        unit: `포`,
      },
      {
        name: `뇌수막염치료제/항생제`,
        price: 55000 / 50,
        unit: `병`,
      },
      {
        name: `말라리아 치료제`,
        price: 30000 / 400,
        unit: `정`,
      },
      /* 2024년 비활성
      {
        name: `에이즈 테스트키트`,
        price: 20000 / 10,
        unit: `개`,
      },
      */
      {
        name: `다회용 면 생리대`,
        price: 7500 / 1,
        unit: `명`,
      },
      {
        name: `응급의료키트`,
        price: 680000 / 1000,
        unit: `명`,
      },
      /* 2024년 비활성
      {
        name: `구충제`,
        price: 18000 / 300,
        unit: `정`,
      },
      */
    ],
  },
  education: {
    name: `교육`,
    icon: Pagination3,
    iconActive: Pagination3H,
    items: [
      {
        name: `연필`,
        price: 10000 / 200,
        unit: `자루`,
      },
      {
        name: `공책`,
        price: 15000 / 30,
        unit: `권`,
      },
      {
        name: `태양광램프`,
        price: 50000 / 1,
        unit: `개`,
      },
      {
        name: `책가방`,
        price: 30000 / 10,
        unit: `개`,
      },
      /* 2024년 비활성
      {
        name: `농구공`,
        price: 5000 / 1,
        unit: `개`,
      },
      {
        name: `줄넘기`,
        price: 6000 / 10,
        unit: `개`,
      },
      */
    ],
  },
  water: {
    name: `식수`,
    icon: Pagination4,
    iconActive: Pagination4H,
    items: [
      {
        name: `식수정화제`,
        price: 30000 / 5000,
        unit: `정`,
      },
      {
        name: `비누`,
        price: 15000 / 30,
        unit: `개`,
      },
      {
        name: `수동식 펌프`,
        price: 430000 / 1,
        unit: `대`,
      },
      /* 2024년 비활성
      {
        name: `콜레라 진단 키트`,
        price: 30000 / 10,
        unit: `개`,
      },
      {
        name: `2중 칸막이 화장실`,
        price: 430000 / 1,
        unit: `개`,
      },
      */
    ],
  },
  vaccination: {
    name: `백신`,
    icon: Pagination5,
    iconActive: Pagination5H,
    items: [
      {
        name: `DTP 예방백신`,
        price: 15000 / 50,
        unit: `회`,
      },
      {
        name: `결핵 예방백신`,
        price: 30000 / 50,
        unit: `회`,
      },
      {
        name: `소아마비 예방백신`,
        price: 20000 / 80,
        unit: `회`,
      },
      {
        name: `홍역 예방백신`,
        price: 35000 / 50,
        unit: `회`,
      },
      {
        name: `파상풍 예방백신`,
        price: 50000 / 250,
        unit: `회`,
      },
      {
        name: `백신 운반용 상자`,
        price: 25000 / 1,
        unit: `개`,
      },
    ],
  },
};
